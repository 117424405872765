import { useState } from "react";
import PropTypes from "prop-types";
import SampleSelector2 from "./SampleSelector2";
import Selector from "./Selector";
import { ReactComponent as PlusIcon } from "../images/plus.svg";

const SampleCsvEditor = props => {

  const { schema, samples, setSamples, categories, organism, setOrganism, allowExtraColumns, inputClass, placeholderClass, selectOptionClass, selectOptionsClass } = props;

  const [pairedMode, setPairedMode] = useState("both");
  const [extraColumns, setExtraColumns] = useState([]);
  console.log(schema)
  console.log(extraColumns)

  const headers = Object.entries(schema).filter(([_,value]) => value.user_override).map(([key,]) => key);
  const requiredHeaders = Object.entries(schema).filter(
    ([key, value]) => value.required && headers.includes(key)
  ).map(([key,]) => key);
  
  const sampleSelected = (sample, id) => {
    const samplesCopy = {...samples};
    if (sample === null) {
      delete samplesCopy[id];
      setOrganism(null);
    } else {
      samplesCopy[sample.id] = {obj: sample};
      if (pairedMode !== "both" && sample.is_paired) {
        samplesCopy[sample.id].pairedMode = pairedMode;
      }
      for (let header of headers) {
        samplesCopy[sample.id][header] = sample[schema[header].property] || "";
      }
      setOrganism(sample.organism)
    }
    setSamples(samplesCopy);
  }

  const pairedModeSelected = mode => {
    setPairedMode(mode);
    const samplesCopy = {...samples};
    for (let sample of Object.values(samplesCopy)) {
      if (mode === "both") {
        delete sample.pairedMode;
      } else {
        sample.pairedMode = mode;
      }
    }
    setSamples(samplesCopy);
  }

  const pairedOptions = [
    {id: "both", label: "Both reads"},
    {id: "first", label: "read1 only"},
    {id: "second", label: "read2 only"},
  ]

  const rowsMissingData = Object.values(samples).filter(sample => {
    for (let header of requiredHeaders) {
      if (!sample[header]) return true;
    }
    return false;
  })

  const usesPaths = Object.values(schema).map(v => v.property).some(
    p => ["input.1", "input.2"].includes(p)
  );
  const hasPaired = Object.values(samples).some(s => s.obj.is_paired);
  const askAboutPairs = usesPaths && hasPaired;

  const headerClass = "py-1 px-3 font-medium text-left border-r last:border-r-0";
  const cellClass = "bg-transparent font-normal text-sm w-full h-full min-h-[28px] rounded-none px-3";

  const plusClicked = () => {
    setExtraColumns([...extraColumns, ""]);
  }

  const crossClicked = index => {
    setSamples(Object.fromEntries(
      Object.entries(samples).map(([id, sample]) => {
        const {[extraColumns[index]]: _, ...rest} = sample;
        return [id, rest];
      })
    ));
    setExtraColumns(extraColumns.filter((_, i) => i !== index));
  }

  return (
    <div className="">
      <div className={`${inputClass} overflow-x-auto pt-0 pb-0 pl-0 pr-0`}>
        <table>
          <thead className="border-b border-gray-300">
            <tr>
              <th className={`${headerClass} w-full max-w-7xl`}>sample</th>
              {headers.map((header, index) => (
                <th key={index} className="py-1 px-3 font-medium text-left border-r last:border-r-0">
                  {header}
                  {requiredHeaders.includes(header) && <span className="text-red-800">*</span>}
                </th> 
              ))}
              {extraColumns.map((_, index) => (
                <th key={index} className="relative py-1 px-3 pr-3.5 font-medium text-left border-r last:border-r-0">
                  <div
                    contentEditable
                    ignoreContentEditableWarning
                    className="min-w-[20px] outline-none whitespace-nowrap"
                    onInput={e => setExtraColumns(extraColumns.map((c, i) => i === index ? e.currentTarget.textContent : c))}
                  />
                    
                  <PlusIcon
                    className="absolute right-px top-px w-3 h-3 cursor-pointer fill-red-600 rotate-45"
                    onClick={() => crossClicked(index)}
                  />
                </th>
              ))}
              {allowExtraColumns && (
                <th className="px-2 cursor-pointer hover:bg-slate-100" onClick={plusClicked}>
                  +
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {Object.entries(samples).map(([id, sample]) => (
              <tr key={id} className="border-b">
                <td>
                  <SampleSelector2
                    inputClass={cellClass}
                    organismId={organism?.id}
                    sample={sample.obj}
                    setSample={sample => sampleSelected(sample, id)}
                    fullMetadata={true}
                    categories={categories}
                  />
                </td>
                {headers.map((header, index) => (
                  <td className="h-6 border-l" key={index}>
                    <input
                      className={cellClass}
                      value={sample[header]}
                      onChange={e => setSamples({...samples, [id]: {...sample, [header]: e.target.value}})}
                    />
                  </td>
                ))}
                {extraColumns.map((_, index) => (
                  <td key={index} className="h-6 border-l">
                    <input
                      className={cellClass}
                      value={sample[extraColumns[index]] || ""}
                      onChange={e => setSamples({...samples, [id]: {...sample, [extraColumns[index]]: e.target.value}})}
                    />
                  </td>
                ))}
                {allowExtraColumns && (
                  <td className="h-6 border-l px-4">
                    
                  </td>
                )}
              </tr>
            ))}
            <tr>
              <td>
                <SampleSelector2
                  inputClass={cellClass}
                  sample={null}
                  setSample={sampleSelected}
                  fullMetadata={true}
                  categories={categories}
                  organismId={organism?.id}
                  placeholder={organism ? `Select a ${organism.name} sample` : "Select a sample"}
                  placeholderClass={placeholderClass}
                />
              </td>
              {headers.map((header) => <td className="h-6 border-l" key={header} />)}
              {extraColumns.map((_, index) => (
                <td key={index} className="h-6 border-l px-4" />
              ))}
              {allowExtraColumns && <td className="h-6 border-l px-4" />}
            </tr>
          </tbody>
        </table>
      </div>
      {askAboutPairs && (
        <div className="mb-4">
          <div className="text-xs mb-1 sm:text-sm">
            You've selected at least one paired-end sample - which reads should be used?
          </div>
          <Selector
            value={pairedMode}
            options={pairedOptions}
            onChange={pairedModeSelected}
            inputClassName="bg-[#F9F9F9] border border-[#A5ACC6] rounded w-32 py-1 text-[#54618D] font-medium px-2 text-xs md:text-sm cursor-pointer"
            optionClassName={selectOptionClass.replace(/h-\d/g, "h-7")}
            optionsClassName={`${selectOptionsClass.replace("w-full", "w-32").replace(/-mt-\d/g, "-mt-px")} text-xs md:text-sm`}
          />
        </div>
      )}
      {rowsMissingData.length > 0 && (
        <div className="text-red-600 text-xs -mt-1 mb-2">
          {rowsMissingData.length} sample{rowsMissingData.length > 1 ? "s are" : " is"} missing data in columns marked as required.
          Pipeline may not run correctly.
        </div>
      )}
    </div>
  )
}

SampleCsvEditor.propTypes = {
  schema: PropTypes.object.isRequired,
  samples: PropTypes.object.isRequired,
  setSamples: PropTypes.func.isRequired,
  categories: PropTypes.array,
  organism: PropTypes.object,
  setOrganism: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  placeholderClass: PropTypes.string.isRequired,
  selectOptionClass: PropTypes.string.isRequired,
  selectOptionsClass: PropTypes.string.isRequired,
};

export default SampleCsvEditor;